// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCgytDmtgMmlBL40WjjCmcl4idObi9eYh8",
  authDomain: "hungry-teams.firebaseapp.com",
  projectId: "hungry-teams",
  storageBucket: "hungry-teams.appspot.com",
  messagingSenderId: "561760473469",
  appId: "1:561760473469:web:28311334ebb3288b5f3c14",
  measurementId: "G-7WN61W2DDR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);