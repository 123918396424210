import './App.css';
import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import hungry from './assets/HUNGRY.png';
import teams from './assets/TEAMS.png';
import './firebase';
import { getFirestore, collection, query, getDocs, where, doc, updateDoc, getDoc, addDoc} from "firebase/firestore";
import { Routes, Route } from "react-router-dom";
import moment from 'moment';
import ellipse from './assets/ellipse.png';
import bigidea from './assets/bigidea.png';
import topwhitehungry from './assets/topwhitehungry.png';
import Landing from '../src/components/landing/Landing';
import Data from '../src/components/data/Data';
import Navigation from '../src/components/navigation/Navigation';
import Forms from '../src/components/forms/forms';
import Pricing from '../src/components/pricing/pricing';

const db = getFirestore();

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function GetDocumentInfo() {
  const [connectionR, setConnectionR] = useState();
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    let search = window.location.search.substring(1).split("&");
    let investorId = '';
    let teamId = '';
    if (search.length > 1) {
      investorId = search[0].substring(4);
      teamId = search[1].substring(4);
      try {
        const q = query(collection(db, 'connectionRequests'), where("investorId", "==", investorId));
        getDocs(q)
        .then(querySnapshot => {
          querySnapshot.forEach((document) => {
            var now = new Date();
            var createdAt = moment(document.data().createdAt.toDate());
            if (document.data().teamId === teamId) {
              if (moment(now).diff(moment(createdAt).add(48, 'hours')) < 0 && !document.data().confirmed) {
                const docRef = doc(db, "connectionRequests", document.id);
                updateDoc(docRef, {
                  confirmed: true
                });
                setExpired(false)
              } else if (moment(now).diff(moment(createdAt).add(48, 'hours')) > 0 || document.data().confirmed) {
                setExpired(true)
              }
              setConnectionR(document.data())
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
      } catch (e) {
        console.log(e)
      }
    }
  }, []);
  return expired
};

function GetStatsInfo () {
  // s = connections, t = teams, i = investors
  const [t, setT] = useState({id: 0, name: 'Teams', value: 0});
  const [i, setI] = useState({id: 1, name: 'Investors', value: 0});
  const [s, setS] = useState({id: 2, name: 'Connections', value: 0});

  useEffect(() => {

    // const q = query(collection(db,'connectionRequests'));
    // getDocs(q)
    // .then((querySnapshot) => {
    //   var newR = 0;
    //   querySnapshot.forEach((doc) => {
    //     if (doc.data().investorId === 'Y5FkfhK7HtQcMagERbWj' && doc.data().cid === "kz2xtGXWmi6O6p6O4Xm6") {
    //       console.log(doc.id)
    //       newR = newR + 1;
    //     }
    //   })
    //   console.log(newR)
    // })

    const q1 = query(doc(db, 'projectStats', 'stats'));
    getDoc(q1)
    .then((doc) => {
      let newT = {...t};
      newT.value = doc.data().numberOfTeams;
      let newI = {...i};
      newI.value = doc.data().numberOfInvestors;
      let newS = {...s};
      newS.value = doc.data().numberOfConnections;

      setT(newT);
      setI(newI);
      setS(newS);
    })
  }, [])
  
  return ([t, i, s]);
};

function UnsubscribeEvent() {
  const [unsubscribed, setUnsubscribed] = useState(false);
  useEffect(() => {
    let search = window.location.search.substring(1).split("&");
    let byeId = '';
    let type = '';
    if (search.length > 1) {
      byeId = search[0].substring(4);
      type = search[1].substring(4);
      try {
        const q = query(collection(db, 'unsubscribe'), where("id", "==", byeId));
        getDocs(q)
        .then(querySnapshot => {
          if (querySnapshot.empty) {
            const docRef = addDoc(collection(db, "unsubscribe"), {
              id: byeId,
              type: type
            });
            setUnsubscribed(true)
          } else {
            setUnsubscribed(true)  
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }, []);
  return unsubscribed;
};

function GetFaqContent() {
  const [faqContent, setFaqContent] = useState([]);

  useEffect(() => {

    async function getFaq() {
      let newFaqContent = [...faqContent];

      const q = query(collection(db, 'websiteFaq'));
      await getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          newFaqContent.push(doc.data());
        })
      })
      newFaqContent.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
      setFaqContent(newFaqContent);
    }

    getFaq();
  }, [])

  return faqContent;
}

function App() {

  const size = useWindowSize();
  const statsSnapshot = GetStatsInfo();
  const faqContent = GetFaqContent();

  let docD = false;

  if (window.location.pathname === '/byebye') {
    UnsubscribeEvent();
  } else if (window.location.pathname === '/confirmation') {
    docD = GetDocumentInfo();
  };

  const [visibleQuestion, setVisibleQuestion] = useState([]);

  const faqUi = <div>
    <div style={{textAlign: 'center', fontSize: '54px', lineHeight: '60px', marginBottom: '24px', fontFamily: 'Cuprum'}}>FAQ</div>
    {faqContent.map(fc => {
      return <div key={fc.order} style={{margin: '0 5% 5% 5%', padding: '2.5%', border: '1px solid black'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}}>
          <div style={{fontSize: '30px', fontWeight: '600', padding: '24px 0', fontFamily: 'Cuprum'}}>{fc.question}</div>
          {/* <div style={{marginTop:'auto', marginBottom: 'auto', top: 0, bottom: 0}}><div className="plus alt" style={{background: visibleQuestion === fc.order ? "conic-gradient(from 90deg at var(--b) var(--b),#fff 180deg,#000 0) calc(100% + var(--b)/2) calc(100% + var(--b)/2)/calc(50%  + var(--b))   calc(50%  + var(--b))" : "conic-gradient(from 90deg at var(--b) var(--b),#fff 90deg,#000 0) calc(100% + var(--b)/2) calc(100% + var(--b)/2)/calc(50%  + var(--b))   calc(50%  + var(--b))"}}></div></div> */}
        </div>
          {fc.answer.map(fca => {
            return <p key={fca} style={{fontSize: '24px', lineHeight: '30px', fontWeight: '300', fontFamily: 'Cuprum'}}>
              {fca}
            </p>
          })}
        </div>
    })}
  </div>

  const statsUi = <div style={{display: 'flex', width: size.width, flexWrap: 'wrap', justifyContent: 'center', margin: '56px 0 56px 0'}}>
    {statsSnapshot.map(statsItem => {
      return <div key={statsItem.id} style={{margin: '24px 36px'}}>
        <div style={{fontSize: '200px', border: '1px solid #000000', textAlign: 'center', padding: '0 24px', fontFamily: 'Cuprum'}}>{statsItem.value}</div>
        <div style={{width: '100%', color: 'white', backgroundColor: 'black', height: '60px', textAlign:'center', fontSize: '36px', lineHeight: '60px', fontFamily: 'Cuprum'}}>{statsItem.name}</div>
        </div>
    })}
  </div>

  const nav = [{id: 'about', name: 'about'}, {id: 'mission', name: 'Big Idea'}, {id: 'faq', name: 'faq'}, {id: 'team', name: 'team'}];

  const navUi = <div style={{position:'fixed', top: 0, width: '100%', display: 'flex', backgroundColor: 'black', justifyContent:'flex-end'}}>
    {nav.map(navitem => {
      return <div key={navitem.id}>
        <Link smooth to={`#${navitem.id}`} style={{ textDecoration: 'none' }}>
          <div style={{color: 'white', fontFamily: 'Cuprum', fontSize: '16px', lineHeight: '24px', padding: '16px', textTransform: 'uppercase', cursor: 'pointer'}}>{navitem.name}</div>
        </Link>
      </div>
    })}
  </div>

  const languages = ([{id: 0, name: 'RUS'}, {id: 1, name: 'ENG'}]);
  const [activeLang, setActiveLang] = useState(1);

  const buttonsGroup = [
    {
      id: 0,
      text: [
        {
          id: 0,
          text: 'Инвестировать',
          lang: 0
        },
        {
          id: 1,
          text: 'To Invest',
          lang: 1
        }
      ],
      link: 'https://airtable.com/shrv7f4hiYHEKW3hN'
    },
    {
      id: 1,
      text: [
        {
          id: 0,
          text: 'Питчить',
          lang: 0
        },
        {
          id: 1,
          text: 'To Pitch',
          lang: 1
        }
      ],
      link: 'https://airtable.com/shr0SRPRhLD2KAfZC'
    }
  ];

  const howItWorks = [
    {id: 0, text: 'First of all HungryTeams is a matching tool.'},
    {id: 1, text: 'Both parties fill in the form. No sensitive data needed. No public profiles you can go through.'},
    {id: 2, text: 'When there is a match on certain criteria, the investor gets an email with a startup brief. If the investor wants to proceed and share contacts, he/she has 48 hours to click the special "connection" link and get an email intro with the founder.'}
  ];

  const teamUi = <div>
    <div style={{textAlign: 'center', fontSize: '54px', lineHeight: '60px', marginBottom: '24px', fontFamily: 'Cuprum'}}>Team</div>
    <div style={{display: 'flex', flexWrap: 'wrap', margin: '0 5% 5% 5%'}}>
      <div style={{flex: 1, display: 'flex', justifyContent: 'center', padding: '24px', flexWrap: 'wrap-reverse'}}>
        <div style={{display: 'flex', justifyContent: 'end', flexDirection: 'column'}}>
          <div style={{fontSize: '40px', lineHeight: '54px', textTransform: 'uppercase', fontFamily: 'Cuprum'}}>Maria Podlesnova</div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button style={{fontFamily:'Cuprum', lineHeight: '40px', minWidth: '150px', height: '40px', backgroundColor: 'black', color: 'white', fontSize: '24px', fontWeight: '600', cursor: 'pointer', border: 'none', margin: '4px'}} onClick={()=> window.open("https://www.facebook.com/podlesnova", "_blank")}>Facebook</button>
            <button style={{fontFamily:'Cuprum', lineHeight: '40px', minWidth: '150px', height: '40px', backgroundColor: 'black', color: 'white', fontSize: '24px', fontWeight: '600', cursor: 'pointer', border: 'none', margin: '4px'}} onClick={()=> window.open("https://www.linkedin.com/in/podlesnova/", "_blank")}>LinkedIn</button>
          </div>
        </div>
        <div style={{margin: 'auto'}}><img src="https://firebasestorage.googleapis.com/v0/b/hungry-teams.appspot.com/o/mpht%201.png?alt=media&token=d86ccc0b-5777-4899-9de3-a62a4264b668" style={{height: '250px'}}/></div>
      </div>
      <div style={{flex: 1, display:'flex', justifyContent: 'center', padding: '24px', flexWrap: 'wrap-reverse'}}>
        <div style={{display: 'flex', justifyContent: 'end', flexDirection: 'column'}}>
          <div style={{fontSize: '40px', lineHeight: '54px', textTransform: 'uppercase', fontFamily: 'Cuprum'}}>Max Grigoryev</div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button style={{fontFamily:'Cuprum', lineHeight: '40px', minWidth: '150px', height: '40px', backgroundColor: 'black', color: 'white', fontSize: '24px', fontWeight: '600', cursor: 'pointer', border: 'none', margin: '4px'}} onClick={()=> window.open("https://www.facebook.com/max.grgrv/", "_blank")}>Facebook</button>
            <button style={{fontFamily:'Cuprum', lineHeight: '40px', minWidth: '150px', height: '40px', backgroundColor: 'black', color: 'white', fontSize: '24px', fontWeight: '600', cursor: 'pointer', border: 'none', margin: '4px'}} onClick={()=> window.open("https://www.linkedin.com/in/mgrigoryev/", "_blank")}>LinkedIn</button>
          </div>
        </div>
        <div style={{margin: 'auto'}}><img src="https://firebasestorage.googleapis.com/v0/b/hungry-teams.appspot.com/o/mght%201.png?alt=media&token=fd8c3816-08d5-4c0c-ac8e-cf8de5fe4aa3" style={{height: '250px'}}/></div>
      </div>
    </div>
  </div>

  const footer = <div style={{width: '100%', backgroundColor:'black', height: '140px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '70px'}}>
    <div style={{display: 'flex', color: 'white'}}>
      <div><img src={ellipse} alt='ellipse' style={{height:'24px', width: '24px'}}/></div>
      <div style={{lineHeight: '24px', marginLeft: '16px', fontFamily: 'Cuprum'}}>2022 HUNGRY TEAMS</div>
    </div>
    <div style={{color: 'white', fontFamily: 'Cuprum'}}>
      DATA@HUNGRYTEAMS.COM
    </div>
  </div>

  const missionUi = <div style={{borderBottom: '1px solid #000000', borderTop: '1px solid #000000', display: 'flex', marginBottom: '24px', flexWrap: 'wrap-reverse'}}>
    <div style={{flex: 1, margin: 'auto', minWidth: '350px', padding: '24px', boxSizing: 'border-box'}}>
      <p style={{fontSize: '24px', lineHeight: '30px', fontWeight: 400, fontFamily: 'Cuprum'}}>Today all talented teams and entrepreneurs lost their homes and fled their countries. Both Ukrainian and Russians who do not support the war.</p>
      <p style={{fontSize: '24px', lineHeight: '30px', fontWeight: 400, fontFamily: 'Cuprum'}}>We consider these people to be the most motivated in the whole world for the years ahead. They won’t step back and will fight for new businesses and their future. Thousands of talented and highly qualified engineers, designers, quality engineers and other IT specialists.</p>
      <p style={{fontSize: '24px', lineHeight: '30px', fontWeight: 400, fontFamily: 'Cuprum'}}>HungryTeams was designed to help especially these teams but we welcome all startups that are founded by extraordinary people with ambitions to become global and relocate to listed locations in #US #Europe #LATAM #Asia #MENA.</p>
    </div>
    <div style={{flex: 1, minWidth: '350px', textAlign: 'center', borderBottom: size.width < 700 ? '1px solid #000000' : 'none', boxSizing: 'border-box', borderLeft: '1px solid #000000'}}>
      <img src={bigidea} alt='bigidea'  style={{objectFit: 'contain', width: '80%', height: '100%'}}/>
    </div>
  </div>

  const landing = 
  <div id= "about">
    {navUi}
    <div style={{display: 'flex', width: size.width, flexWrap: 'wrap', paddingTop: '56px'}}>
      <div style={{flex: 1, backgroundColor: 'black', minWidth: '390px'}}>
        <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', height:'100%'}}>
          <img src={hungry} alt='hungry' style={{width: '80%', margin: size.width <= 390 ? '98px 36px 36px 36px' : '36px'}}/>
          <img src={teams} alt='teams' style={{width: '70%', margin: '36px'}}/>
        </div>
      </div>
      <div style={{flex: 1, overflow: 'scroll', minWidth: '390px'}}>
        <div style={{width: '100%', borderTop: '1px solid #000000', borderBottom: '1px solid #000000', marginTop: size.width <= 390 ? '0px' : '0px', padding: '60px 0'}}>
          <p style={{fontFamily:'Cuprum', width: '80%', marginLeft: 'auto', marginRight: 'auto', fontSize: '24px', lineHeight: '30px'}}>
            This platform aggregates all talented startup teams from Eastern Europe (especially from Ukraine, Belarus & Russia), who have already launched their business in developed countries or are ready to relocate their tech business right now.
          </p>
          <p style={{fontFamily:'Cuprum', width: '80%', marginLeft: 'auto', marginRight: 'auto', fontSize: '24px', lineHeight: '30px'}}>
            Provide them with resources to make a significant contribution to the economy of #US, #Europe, #MENA, #LATAM and #ASIA.
          </p>
        </div>
        <div style={{marginTop: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
          {buttonsGroup.map(btn => { 
            return <button key={btn.id} style={{fontFamily:'Cuprum', lineHeight: '44px', minWidth: '250px', height: '60px', backgroundColor: 'black', color: 'white', fontSize: '24px', fontWeight: '600', cursor: 'pointer', border: 'none', margin: '16px'}} onClick={()=> window.open(btn.link, "_blank")}>{btn.text.filter(b => b.lang === activeLang)[0].text}</button>
          })}
        </div>
        <div style={{width: '100%', borderTop: '1px solid #000000', borderBottom: '1px solid #000000', padding: '60px 0'}}>
            {howItWorks.map(hiw => {
              return <div key={hiw.id} style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                <p style={{fontFamily:'Cuprum', fontSize: '24px', lineHeight: '30px'}}>{hiw.text}</p>
              </div>
            })}
        </div>
      </div>
    </div>
    <div>
      {statsUi}
    </div>
    <div id='mission'>
      {missionUi}
    </div>
    <div id= "faq">
      {faqUi}
    </div>
    <div id= "team">
      {teamUi}
    </div>
    <div>
      {footer}
    </div>
  </div>

  const confirmation = <div>
    <div style={{width: '100%', height: '160px', backgroundColor: 'black'}}>
      <img src={topwhitehungry} alt='topwhitehungry' style={{margin: '30px 0 30px 105px'}}/>
    </div>
    <div style={{maxWidth: '1000px', border: '1px solid #000000', padding: '30px 22px', margin: '10% auto' }}>
      {
        !docD ? 
        <div style={{fontFamily:'Cuprum', fontSize: '30px', lineHeight: '36px', textTransform:'uppercase', color: '#7D7D7D'}}><p>Thanks for confirming your interest. You will receive an introductory email shortly.</p><p>Reach us at data@hungryteams.com if something goes wrong.</p></div>
        :
        <div style={{fontFamily:'Cuprum', fontSize: '30px', lineHeight: '36px', textTransform:'uppercase', color: '#7D7D7D'}}><p>Unfortunately, the link is no longer valid. Investors have 48 hours to confirm the interest. You are either outside of this time frame or have already confirmed your interest before.</p><p>Although if you still want to connect with the team, reach us at data@hungryteams.com and we'll process the request manually.</p></div>
      }
    </div>
    <div style={{bottom: 0, position: 'fixed', width: '100%'}}>
      {footer}
    </div>
  </div>

  const byescreen = <div>
    <div style={{width: '100%', height: '160px', backgroundColor: 'black'}}>
      <img src={topwhitehungry} alt='topwhitehungry' style={{margin: '30px 0 30px 105px'}}/>
    </div>
    <div style={{maxWidth: '1000px', border: '1px solid #000000', padding: '30px 22px', margin: '10% auto' }}>
      <div style={{fontFamily:'Cuprum', fontSize: '30px', lineHeight: '36px', textTransform:'uppercase', color: '#7D7D7D'}}><p style={{fontWeight: 600, color: 'black'}}>Bye-bye</p><p>Happy you're not hungry anymore. We are deeply grateful for the time we spent together.</p><p>Remember, you can reach us at data@hungryteams.com anytime.</p></div>
    </div>
    <div style={{bottom: 0, position: 'fixed', width: '100%'}}>
      {footer}
    </div>
  </div>

  return (
    <div>
      <div style={{minWidth: '1000px'}}>
        <Navigation />
      </div>
        <Routes>
          <Route path='/2022' element={landing}></Route>
          <Route path='/confirmation' element={confirmation}></Route>
          <Route path='/byebye' element={byescreen}></Route>
          <Route path='/' element={<Landing/>} ></Route>
          <Route path='/newdatapage' element={<Data/>} ></Route>
          <Route path='/newtoinvest' element={<Forms form={"toInvest"}/>} ></Route>
          <Route path='/newtopitch' element={<Forms form={"toPitch"}/>} ></Route>
          <Route path='/newpricing' element={<Pricing/>} ></Route>
        </Routes>
    </div>
  );
}

export default App;
