import React from 'react';
import { useNavigate } from 'react-router-dom';

function Forms (form) {
    
    const navigate = useNavigate();

    let formView = <div></div>
    switch(form.form) {
        case ("toInvest"):
            formView = <div>
                <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
                <iframe src="https://airtable.com/embed/appgAmnZtPHgjK0t9/shrv7f4hiYHEKW3hN?backgroundColor=gray" height="800" style={{background: "transparent", border: "1px solid #ccc", width: "100%", onmousewheel: "", frameborder: "0"}}></iframe>
            </div>
            break;
        case ("toPitch"):
            formView = <div>
                <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
                <iframe src="https://airtable.com/embed/appgAmnZtPHgjK0t9/shr0SRPRhLD2KAfZC?backgroundColor=gray" height="800" style={{background: "transparent", border: "1px solid #ccc", width: "100%", onmousewheel: "", frameborder: "0"}}></iframe>
            </div>
            break;
        default: console.log('Form?')
    }

    const footer = <div style={{backgroundColor: 'black', color: 'white', display: 'flex', padding: '2em', fontSize: '16px', lineHeight: '24px', textTransform: 'uppercase', justifyContent: 'space-evenly', boxSizing: 'border-box', flexWrap: 'wrap'}}>
        <div>
            <div style={{marginBottom: '24px'}}>Hungryteams Inc &copy; 2024</div>
            <div>About</div>
            <div>Users Agreement</div>
            <div>Private Statement</div>
        </div>
        <div>
            <div style={{marginBottom: '24px'}}>Data@hungryteams.com</div>
            <div>LinkedIn</div>
            <div>Twitter</div>
            <div>Facebook</div>
        </div>
        <div>
            <div onClick={() => navigate("/newtoinvest")}>To Invest</div>
            <div onClick={() => navigate("/newtopitch")}>To Pitch</div>
            <div onClick={() => navigate("/newpricing")}>Pricing</div>
            <div onClick={() => navigate("/newdatapage")}>Data</div>
            <div>FAQ</div>
        </div>
    </div>

    return (
        <div>
            <div style={{maxWidth: "1000px", margin: "2em auto", height: "100%"}}>
                {formView}
            </div>
            {footer}
        </div>
    )

};

export default Forms;