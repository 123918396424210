import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, getDocs, where, doc, updateDoc, getDoc, addDoc} from "firebase/firestore";
import arrow from '../../assets/arrow.png';
import bigidea from '../../assets/bigidea.png';
import { useNavigate } from 'react-router-dom'

const db = getFirestore();

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  };

function GetStatsInfo () {

    // s = connections, t = teams, i = investors
    const [t, setT] = useState({id: 0, name: 'Teams', value: 0});
    const [i, setI] = useState({id: 1, name: 'Investors', value: 0});
    const [s, setS] = useState({id: 2, name: 'Connections', value: 0});
  
    useEffect(() => {
  
      // const q = query(collection(db,'connectionRequests'));
      // getDocs(q)
      // .then((querySnapshot) => {
      //   var newR = 0;
      //   querySnapshot.forEach((doc) => {
      //     if (doc.data().investorId === 'Y5FkfhK7HtQcMagERbWj' && doc.data().cid === "kz2xtGXWmi6O6p6O4Xm6") {
      //       console.log(doc.id)
      //       newR = newR + 1;
      //     }
      //   })
      //   console.log(newR)
      // })
  
      const q1 = query(doc(db, 'projectStats', 'stats'));
      getDoc(q1)
      .then((doc) => {
        let newT = {...t};
        newT.value = doc.data().numberOfTeams;
        let newI = {...i};
        newI.value = doc.data().numberOfInvestors;
        let newS = {...s};
        newS.value = doc.data().numberOfConnections;
  
        setT(newT);
        setI(newI);
        setS(newS);
      })
    }, [])
    
    return ([t, i, s]);
};

function Landing () {

    const size = useWindowSize();
    const statsSnapshot = GetStatsInfo();
    const navigate = useNavigate();

    const howItWorksText = [
        {id: 0, text: "Choose your role (Founder or Investor) and fill the relevant form"},
        {id: 1, text: "Our investor gets an email with the founder's blurb once the startup is matching the criteria: vertical or location"},
        {id: 2, text: "If the blurb sparks interest, investor may request an intro and both parties get an introductory email"},
    ];

    const header = <div style={{display: 'flex', justifyContent: 'space-around', margin: '4em', flexWrap: 'wrap'}}>
        <div style={{textTransform: 'uppercase', flex: 1}}>
            <div style={{fontSize: '80px'}}>Matching</div>
            <div style={{backgroundColor: 'black', color: 'white', fontSize: '80px', width: 'fit-content'}}>Tool</div>
            <div style={{paddingTop: '12px', fontSize: '40px', lineHeight: '44px', textTransform: 'uppercase'}}>For founders from emerging markets and capital around the globe</div>
        </div>
        <div style={{padding: '0 4em', boxSizing: 'border-box'}}>
            <div style={{display: 'flex', padding: '24px', flexWrap: 'wrap'}}>
            {statsSnapshot.map(statsItem => {
                return <div key={statsItem.id} style={{margin: '12px'}}>
                    <div style={{fontSize: '80px', textAlign: 'center', padding: '0 12px', fontFamily: 'Cuprum', weight: 600}}>{statsItem.value}</div>
                    <div style={{width: '100%', textAlign:'center', fontSize: '28px', lineHeight: '60px', fontFamily: 'Cuprum'}}>{statsItem.name}</div>
                    </div>
                })}
            </div>
            <div style={{border: '1px solid black', width: '100%', display: 'flex', justifyContent:'end', padding: '8px 16px', boxSizing:'border-box'}}>
                <div style={{marginTop: 'auto', marginBottom: 'auto', top: 0, bottom: 0}}>
                    <svg width="138" height="16" viewBox="0 0 138 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M137.707 8.70712C138.098 8.31659 138.098 7.68343 137.707 7.29291L131.343 0.928944C130.953 0.538419 130.319 0.538419 129.929 0.928944C129.538 1.31947 129.538 1.95263 129.929 2.34316L135.586 8.00001L129.929 13.6569C129.538 14.0474 129.538 14.6806 129.929 15.0711C130.319 15.4616 130.953 15.4616 131.343 15.0711L137.707 8.70712ZM-8.74228e-08 9L137 9.00001L137 7.00001L8.74228e-08 7L-8.74228e-08 9Z" fill="black"/>
                    </svg>
                </div>
                <div style={{fontSize: '28px', lineHeight: '32px', marginLeft: '12px'}}>Numbers</div>
            </div>
        </div>
    </div>

    const howItWorks = <div>
        <div style={{textAlign: 'center', textTransform: 'uppercase', fontSize: '40px', lineHeight: '44px'}}>How it works?</div>
        <div style={{display: 'flex', justifyContent: 'space-around', margin: '4em', flexWrap: 'wrap'}}>
            {howItWorksText.map(hiw => {
                return <div key={hiw.id} style={{backgroundColor: 'black', margin: '0 12px', flex: 1, display: 'flex'}}>
                    <svg style={{marginTop: '-16px', marginLeft: '-16px', flex: 1}} width="106" height="90" viewBox="0 0 106 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M104.243 49.2426C106.586 46.8995 106.586 43.1005 104.243 40.7574L66.0589 2.57359C63.7157 0.230446 59.9167 0.230446 57.5736 2.57359C55.2304 4.91674 55.2304 8.71573 57.5736 11.0589L91.5147 45L57.5736 78.9411C55.2304 81.2843 55.2304 85.0833 57.5736 87.4264C59.9167 89.7696 63.7157 89.7696 66.0589 87.4264L104.243 49.2426ZM2.87855e-09 51L100 51L100 39L-2.87855e-09 39L2.87855e-09 51Z" fill="white"/>
                    </svg>
                    <div style={{color: 'white', fontSize: '20px', lineHeight: '28px', flex: 2, margin: '24px 16px'}}>{hiw.text}</div>
                </div>
            })}
        </div>
    </div>

    const missionUi = <div style={{borderBottom: '1px solid #000000', borderTop: '1px solid #000000', display: 'flex', marginBottom: '24px', flexWrap: 'wrap-reverse'}}>
        <div style={{flex: 1, margin: 'auto', minWidth: '350px', padding: '24px', boxSizing: 'border-box'}}>
            <p style={{fontSize: '24px', lineHeight: '30px', fontWeight: 400, fontFamily: 'Cuprum'}}>Today all talented teams and entrepreneurs lost their homes and fled their countries. Both Ukrainian and Russians who do not support the war.</p>
            <p style={{fontSize: '24px', lineHeight: '30px', fontWeight: 400, fontFamily: 'Cuprum'}}>We consider these people to be the most motivated in the whole world for the years ahead. They won’t step back and will fight for new businesses and their future. Thousands of talented and highly qualified engineers, designers, quality engineers and other IT specialists.</p>
            <p style={{fontSize: '24px', lineHeight: '30px', fontWeight: 400, fontFamily: 'Cuprum'}}>HungryTeams was designed to help especially these teams but we welcome all startups that are founded by extraordinary people with ambitions to become global and relocate to listed locations in #US #Europe #LATAM #Asia #MENA.</p>
        </div>
        <div style={{flex: 1, minWidth: '350px', textAlign: 'center', borderBottom: size.width < 700 ? '1px solid #000000' : 'none', boxSizing: 'border-box', borderLeft: '1px solid #000000'}}>
            <img src={bigidea} alt='bigidea'  style={{objectFit: 'contain', width: '80%', height: '100%'}}/>
        </div>
    </div>

    const forFounders = [
        {id: 0, text: 'in one click send your pitch directly to relevant investors in preferred locations;'},
        {id: 1, text: 'your pitch will be shown immediately for those who are already on the platform and for any new one with the same criteria;'},
        {id: 2, text: 'no public profiles;'},
        {id: 3, text: 'person connections with ivnestors over the email.'}
    ];

    const forInvestors = [
        {id: 0, text: "meet founders that don't have public rounds yet"},
        {id: 1, text: 'monitoring all ambitious founders from all or preferrable emerging markets without any regional scouting;'},
        {id: 2, text: 'regular pipeline without a penny spent;'},
        {id: 3, text: 'nobody will see you as an investor until you want it.'}
    ]

    const whyUseHT = <div>
        <div style={{textAlign: 'center', textTransform: 'uppercase', fontSize: '40px', lineHeight: '44px'}}>Why use Hungry Teams?</div>
        <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
            <div style={{flex: 1, padding: '2em'}}>
                <div style={{width: '100%', backgroundColor: 'black', color:'white', padding: "0.5em 1em", fontSize: '20px', boxSizing: 'border-box'}}>For Founders</div>
                <ul>
                {forFounders.map(ffm => {
                    return <li key={ffm.id} style={{fontSize: '16px', lineHeight: '24px', borderRight:'1px solid black', paddingRight: '12px'}}>{ffm.text}</li>
                })}
                </ul>
            </div>
            <div style={{flex: 1, padding: '2em'}}>
                <div style={{width: '100%', backgroundColor: 'black', color:'white', padding: "0.5em 1em", fontSize: '20px', boxSizing: 'border-box'}}>For Investors</div>
                <ul>
                {forInvestors.map(ffi => {
                    return <li key={ffi.id} style={{fontSize: '16px', lineHeight: '24px',  borderRight:'1px solid black', paddingRight: '12px'}}>{ffi.text}</li>
                })}
                </ul>
            </div>
        </div>
        <div style={{margin: '2em', boxSizing: 'borderBox', display: 'flex', justifyContent: 'center', padding: '1em', border: '1px solid black'}}>
            <div style={{fontSize: '24px', textTransform: 'uppercase'}}>Our core matching service is and will be free of charge. Choose additional services here</div>
            <div style={{display: 'flex', marginLeft: '16px'}}>
                <div style={{margin: '4px'}}>
                    <svg width="46" height="16" viewBox="0 0 46 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M45.7071 8.70711C46.0976 8.31659 46.0976 7.68342 45.7071 7.2929L39.3431 0.928936C38.9526 0.538411 38.3195 0.538411 37.9289 0.928936C37.5384 1.31946 37.5384 1.95262 37.9289 2.34315L43.5858 8L37.9289 13.6569C37.5384 14.0474 37.5384 14.6805 37.9289 15.0711C38.3195 15.4616 38.9526 15.4616 39.3431 15.0711L45.7071 8.70711ZM-8.74228e-08 9L45 9L45 7L8.74228e-08 7L-8.74228e-08 9Z" fill="black"/>
                    </svg>
                </div>
                <div style={{fontSize: '24px', textTransform: 'uppercase', backgroundColor: 'black', color: 'white', cursor:"pointer"}} onClick={() => navigate("/newpricing")}>Pricing</div>
            </div>
        </div>
    </div>

    const moreAboutTeams = <div>
        <div style={{textAlign: 'center', textTransform: 'uppercase', fontSize: '40px', lineHeight: '44px'}}>More about teams</div>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <div style={{flex: 1, margin: '1em', border: '1px solid black', padding: '24px', boxSizing: 'border-box', boxShadow: '4px 4px 2px 1px grey'}}>
                <div style={{position: 'absolute', textTransform: 'uppercase', fontSize: '24px'}}>
                    <div style={{marginTop: '20px'}}>Solid IT team for acqui-hiring</div>
                    <div style={{marginTop: '16px'}}>80</div>
                    <div style={{marginTop: '36px'}}>MVP Stage</div>
                    <div style={{marginTop: '16px'}}>100</div>
                    <div style={{marginTop: '48px', width: '50%'}}>Pre-seed: MVP with proven hypothesis </div>
                    <div style={{marginTop: '16px'}}>50</div>
                </div>
                <svg width="450" height="338" viewBox="0 0 450 338" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M367.385 313.225C330.392 290.828 303.404 255.089 291.988 213.378C280.572 171.667 285.599 127.165 306.032 89.0519L350.667 112.981C336.364 139.66 332.845 170.811 340.836 200.009C348.827 229.207 367.719 254.224 393.614 269.902L367.385 313.225Z" fill="#CCCACA" fill-opacity="0.8"/>
                    <path d="M454.2 337.628C425.828 337.524 397.822 330.269 372.831 316.384L397.426 272.113C414.866 281.802 434.403 286.88 454.2 286.983V337.628Z" fill="#9D9D9D" fill-opacity="0.8"/>
                    <path d="M454.507 0C448.481 0.0109634 442.446 0.344599 436.427 1.00409C410.325 3.86411 385.25 12.7736 363.196 27.0244C341.141 41.2752 322.715 60.4742 309.382 83.0955L353.012 108.811C362.345 92.9762 375.243 79.5369 390.681 69.5613C406.12 59.5858 423.672 53.3491 441.943 51.3471C446.126 50.8888 450.32 50.6553 454.507 50.6445V0Z" fill="black" fill-opacity="0.8"/>
                    <path d="M0.333333 161C0.333333 162.473 1.52724 163.667 3 163.667C4.47276 163.667 5.66667 162.473 5.66667 161C5.66667 159.527 4.47276 158.333 3 158.333C1.52724 158.333 0.333333 159.527 0.333333 161ZM291.333 161C291.333 162.473 292.527 163.667 294 163.667C295.473 163.667 296.667 162.473 296.667 161C296.667 159.527 295.473 158.333 294 158.333C292.527 158.333 291.333 159.527 291.333 161ZM3 161.5H294V160.5H3V161.5Z" fill="black" fill-opacity="0.8"/>
                    <path d="M0.333333 54C0.333333 55.4728 1.52724 56.6667 3 56.6667C4.47276 56.6667 5.66667 55.4728 5.66667 54C5.66667 52.5272 4.47276 51.3333 3 51.3333C1.52724 51.3333 0.333333 52.5272 0.333333 54ZM349.333 54C349.333 55.4728 350.527 56.6667 352 56.6667C353.473 56.6667 354.667 55.4728 354.667 54C354.667 52.5272 353.473 51.3333 352 51.3333C350.527 51.3333 349.333 52.5272 349.333 54ZM3 54.5H352V53.5H3V54.5Z" fill="black" fill-opacity="0.8"/>
                    <path d="M0.333333 302C0.333333 303.473 1.52724 304.667 3 304.667C4.47276 304.667 5.66667 303.473 5.66667 302C5.66667 300.527 4.47276 299.333 3 299.333C1.52724 299.333 0.333333 300.527 0.333333 302ZM403.333 302C403.333 303.473 404.527 304.667 406 304.667C407.473 304.667 408.667 303.473 408.667 302C408.667 300.527 407.473 299.333 406 299.333C404.527 299.333 403.333 300.527 403.333 302ZM3 302.5H406V301.5H3V302.5Z" fill="black" fill-opacity="0.8"/>
                </svg>
            </div>
            <div style={{flex: 1, margin: '1em', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                <div style={{fontSize: '24px', lineHeight: '28px'}}>We don't share any public profiles of our members, but you could always find infographics with time to day data.</div>
                <div style={{display: 'flex', border: '1px solid black', margin: '16px 0'}}>
                    <div style={{flex: 1, padding: '0.5em', marginTop: '6px', marginLeft: '4px'}}>
                        <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM4.96278e-10 9L30 9L30 7L-4.96278e-10 7L4.96278e-10 9Z" fill="black"/>
                        </svg>
                    </div>
                    <div style={{flex: 5, marginLeft: '4px', backgroundColor: 'black', color: 'white', padding: '0.5em', fontSize: '20px', cursor: "pointer"}} onClick={() => navigate("/newdatapage")}>click here for /data</div>
                </div>
            </div>
        </div>
    </div>

    const collaboration = <div style={{display: 'flex', flexWrap: 'wrap'}}>
        <div style={{textTransform: 'uppercase', fontSize: '40px', lineHeight: '44px', padding: '24px 1em', flex: 1}}>We are open to collaborate with 3rd parties</div>
        <div style={{flex: 1, padding: '24px 2em'}}>
            <div style={{}}>
                <div style={{backgroundColor: 'black', color:'white', padding: "0.5em 1em", fontSize: '20px', boxSizing: 'border-box'}}>Country Agencies</div>
                <div style={{margin: '16px 0 16px 16px', boxSizing: 'border-box', borderRight: '1px solid black', padding: '12px'}}>
                    If you are responsible for country promotion for tech talents or could provide special migration programs.
                </div>
            </div>
            <div>
                <div style={{width: '100%', backgroundColor: 'black', color:'white', padding: "0.5em 1em", fontSize: '20px', boxSizing: 'border-box'}}>Service Providers</div>
                <div style={{margin: '16px 0 16px 16px', boxSizing: 'border-box', borderRight: '1px solid black', padding: '12px'}}>
                    All B2B-services for founders & business: from the very beginning till business shutting down.
                </div>
            </div>
            <div style={{display: 'flex', border: '1px solid black', margin: '24px 0'}}>
                    <div style={{flex: 1, padding: '0.5em', marginTop: '6px', marginLeft: '4px'}}>
                        <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM4.96278e-10 9L30 9L30 7L-4.96278e-10 7L4.96278e-10 9Z" fill="black"/>
                        </svg>
                    </div>
                <div style={{flex: 5, marginLeft: '4px', backgroundColor: 'black', color: 'white', padding: '0.5em', fontSize: '20px', cursor: "pointer"}} onClick={() => navigate("/newdatapage")}>click here for /data</div>
            </div>
        </div>
    </div>

    const punchline = <div style={{padding: '2em', margin: '2em', backgroundColor: 'black', color: 'white', textTransform: 'uppercase', display: 'flex', flexWrap: 'wrap'}}>
        <div style={{fontSize: '40px', lineHeight:'44px', flex: 3}}>We are building a highway for talents, money and locations where no intermediary is needed.</div>
        <div style={{transform: 'rotate(90deg)', margin: 'auto'}}>
            Hungry Teams
        </div>
        <div style={{position: 'absolute'}}></div>
    </div>

    const footer = <div style={{backgroundColor: 'black', color: 'white', display: 'flex', padding: '2em', fontSize: '16px', lineHeight: '24px', textTransform: 'uppercase', justifyContent: 'space-evenly', boxSizing: 'border-box', flexWrap: 'wrap'}}>
        <div>
            <div style={{marginBottom: '24px'}}>Hungryteams Inc &copy; 2024</div>
            <div>About</div>
            <div>Users Agreement</div>
            <div>Private Statement</div>
        </div>
        <div>
            <div style={{marginBottom: '24px'}}>Data@hungryteams.com</div>
            <div>LinkedIn</div>
            <div>Twitter</div>
            <div>Facebook</div>
        </div>
        <div>
            <div>To Invest</div>
            <div>To Pitch</div>
            <div>Pricing</div>
            <div>Data</div>
            <div>FAQ</div>
        </div>
    </div>

    return (
        <div style={{minWidth: '1000px'}}>
            {header}
            {howItWorks}
            {missionUi}
            {whyUseHT}
            {moreAboutTeams}
            {collaboration}
            {punchline}
            {footer}
        </div>
    );
}

export default Landing;