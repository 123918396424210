import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, getDocs, where, doc, updateDoc, getDoc, addDoc} from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

const db = getFirestore();

function GetStatsInfo () {

    // s = connections, t = teams, i = investors
    const [sectors, setSectors] = useState([
        {id: 0, name: "Adtech", count: 0},
        {id: 1, name: 'Aerospace', count: 0},
        {id: 2, name: 'Agrotech', count: 0},
        {id: 3, name: 'AI / ML', count: 0},
        {id: 4, name: 'AR / VR', count: 0},
        {id: 5, name: 'Beauty', count: 0},
        {id: 6, name: 'Biotech', count: 0},
        {id: 7, name: 'Blockchain / Crypto / Web 3.0', count: 0},
        {id: 8, name: 'Cleantech', count: 0},
        {id: 9, name: 'Consumer', count: 0},
        {id: 10, name: 'Cybersecurity', count: 0},
        {id: 11, name: 'Deep tech', count: 0},
        {id: 12, name: 'Digital Health', count: 0},
        {id: 13, name: 'Edtech', count: 0},
        {id: 14, name: 'ESG', count: 0},
        {id: 15, name: 'Fintech', count: 0},
        {id: 16, name: 'Foodtech', count: 0},
        {id: 17, name: 'Gaming', count: 0},
        {id: 18, name: 'Legal Tech', count: 0},
        {id: 19, name: 'Media', count: 0},
        {id: 20, name: 'Mental Health', count: 0},
        {id: 21, name: 'No-code', count: 0},
        {id: 22, name: 'Productivity Tools', count: 0},
        {id: 23, name: 'Transportation', count: 0},
        {id: 24, name: 'Proptech', count: 0},
        {id: 25, name: 'E-commerce', count: 0},
        {id: 26, name: 'Automotive', count: 0},
        {id: 27, name: 'Dating', count: 0},
        {id: 28, name: 'Eco', count: 0},
        {id: 29, name: 'Pharma', count: 0},
        {id: 30, name: 'Open Source', count: 0},
        {id: 31, name: 'Hardware', count: 0},
        {id: 32, name: 'IoT', count: 0},
        {id: 33, name: 'Fashion', count: 0},
        {id: 34, name: 'Delivery / Logistics', count: 0},
        {id: 35, name: 'Social', count: 0},
        {id: 36, name: 'HR Tech', count: 0}
    ]);
  
    useEffect(() => {
  
        const newSectors = [...sectors];
        const docRef = doc(db, "projectStats", "sectorStats");
        getDoc(docRef)
        .then((docSnap) => {
            if (docSnap.exists()) {
                let sectorStatsFromDB = Object.keys(docSnap.data()).map((key) => ({name: key, count: docSnap.data()[key]}));
                newSectors.forEach(ns => ns.count = sectorStatsFromDB.filter(sfd => sfd.name === ns.name)[0].count);
                setSectors(newSectors);
            } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            }
        })
        .catch((e) => {
            console.log("Hm", e)
        })

    }, [])
    
    return (sectors);
};

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  };

function Data () {

    const size = useWindowSize();
    const stats = GetStatsInfo();
    const navigate = useNavigate();

    console.log(stats);

    const header = <div style={{display: 'flex', justifyContent: 'space-around', margin: '4em', flexWrap: 'wrap'}}>
        <div style={{textTransform: 'uppercase', flex: 1}}>
            <div style={{backgroundColor: 'black', color: 'white', fontSize: '80px', width: 'fit-content'}}>Data</div>
            <div style={{fontSize: '80px'}}>Of hungryteams</div>
        </div>
    </div>

    const sectorBars = <div style={{display: 'flex', margin: '1em', flexWrap: 'wrap'}}>
        <div style={{flex: 1}}>
            {stats.slice(0,19).map(sector => {
                return <div key={sector.name} style={{display: 'flex', margin: '8px', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{lineHeight: '24px'}}>{sector.name}</div>
                    <div style={{border: '1px solid #ACACAC', width: '300px'}}>
                        <div style={{backgroundColor: 'black', width: 4*sector.count, color: 'white', marginLeft: 'auto', marginRight: 0, padding: '4px', minWidth: '8px'}}>{sector.count}</div>
                    </div>
                </div>
            })}
        </div>
        <div style={{flex: 1}}>
            {stats.slice(19,37).map(sector => {
                return <div key={sector.name} style={{display: 'flex', margin: '8px', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{lineHeight: '24px'}}>{sector.name}</div>
                <div style={{border: '1px solid #ACACAC', width: '300px'}}>
                    <div style={{backgroundColor: 'black', width: 4*sector.count, color: 'white', marginLeft: 'auto', marginRight: 0, padding: '4px', minWidth: '8px'}}>{sector.count}</div>
                </div>
            </div>
            })}
        </div>
    </div>

    const sectorStats = <div>
        <div style={{display: 'flex', margin: '1em', flexWrap: 'wrap'}}>
            <div style={{padding: '16px', backgroundColor: 'black', color: 'white', textAlign: 'center', textTransform: 'uppercase', border: '1px solid black', flex: 1}}>Founders on hungryteams</div>
            <div style={{padding: '16px', textAlign: 'center', textTransform: 'uppercase', border: '1px solid black', flex: 1}}>Run business in</div>
        </div>
        {sectorBars}
    </div>

    const stagesHeader = <div style={{display: 'flex'}}>
        <div style={{flex: 1}}></div>
        <div style={{flex: 1, textTransform: 'uppercase', fontSize: '24px', lineHeight: '36px', border: '1px solid black', textAlign: 'right', padding: '12px'}}>Right now are at stages</div>
    </div>

    const stages = <div style={{}}>
        <div style={{margin: '2em 1em'}}>
            {stagesHeader}
        </div>
        <div style={{margin: '1em'}}>
            All diagrams display current depersonalized startups' data
        </div>
        <div style={{width: "1000px", padding: '1em', boxSizing: 'border-box', position: "relative", margin: "auto"}}>
            <div style={{position: 'absolute', textTransform: 'uppercase', fontSize: '24px'}}>
                <div style={{marginTop: '66px'}}>Solid IT team for acqui-hiring</div>
                <div style={{marginTop: '16px'}}>80</div>
                <div style={{marginTop: '104px'}}>MVP Stage</div>
                <div style={{marginTop: '16px'}}>100</div>
                <div style={{marginTop: '108px', width: '50%'}}>Pre-seed: MVP with proven hypothesis </div>
                <div style={{marginTop: '16px'}}>50</div>
            </div>
            <div style={{position: 'absolute', textTransform: 'uppercase', fontSize: '24px', right: 0, maxWidth: "260px"}}>
                <div style={{marginTop: '112px', right: 0}}>Seed: Product With First Sales</div>
                <div style={{marginTop: '16px'}}>50</div>
                <div style={{marginTop: '128px', right: 0}}>Series A/B + For International Expansion</div>
                <div style={{marginTop: '16px'}}>50</div>
            </div>
            <svg width="100%" height="550" viewBox="0 0 1226 550" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M470.578 510.246C410.315 473.762 366.352 415.542 347.755 347.594C329.159 279.647 337.348 207.153 370.633 145.066L443.343 184.046C420.043 227.507 414.311 278.253 427.329 325.816C440.346 373.379 471.121 414.133 513.304 439.672L470.578 510.246Z" fill="#CCCACA"/>
                <path d="M644.898 548.144C587.679 554.826 529.807 543.37 479.449 515.393L519.514 443.275C554.765 462.859 595.276 470.878 635.329 466.201L644.898 548.144Z" fill="#9D9D9D"/>
                <path d="M882.251 330.94C870.881 385.666 843.105 435.628 802.623 474.169C762.141 512.71 710.876 538.001 655.658 546.671L642.861 465.17C681.513 459.101 717.399 441.397 745.736 414.418C774.073 387.44 793.517 352.466 801.476 314.158L882.251 330.94Z" fill="#666666"/>
                <path d="M884.004 321.715C890.923 281.576 888.839 240.401 877.903 201.166C866.967 161.93 847.452 125.614 820.768 94.8403L758.438 148.888C777.117 170.429 790.777 195.851 798.432 223.316C806.088 250.781 807.546 279.603 802.703 307.701L884.004 321.715Z" fill="#353535"/>
                <path d="M814.053 87.3755C784.869 56.1029 748.792 32.0694 708.691 17.1853C668.589 2.30124 625.568 -3.02291 583.048 1.63608C540.527 6.29507 499.68 20.8087 463.753 44.0233C427.826 67.2379 397.81 98.5134 376.09 135.364L447.163 177.254C462.367 151.459 483.378 129.567 508.527 113.316C533.676 97.0661 562.269 86.9065 592.034 83.6453C621.798 80.384 651.912 84.1109 679.984 94.5297C708.055 104.949 733.308 121.772 753.737 143.663L814.053 87.3755Z" fill="black"/>
                <path d="M0.333822 275C0.333822 276.473 1.52773 277.667 3.00049 277.667C4.47325 277.667 5.66716 276.473 5.66716 275C5.66716 273.527 4.47325 272.333 3.00049 272.333C1.52773 272.333 0.333822 273.527 0.333822 275ZM359.334 275C359.334 276.473 360.528 277.667 362 277.667C363.473 277.667 364.667 276.473 364.667 275C364.667 273.527 363.473 272.333 362 272.333C360.528 272.333 359.334 273.527 359.334 275ZM3.00049 275.5L362 275.5V274.5L3.00049 274.5V275.5Z" fill="black"/>
                <path d="M0.333822 56C0.333822 57.4728 1.52773 58.6667 3.00049 58.6667C4.47325 58.6667 5.66716 57.4728 5.66716 56C5.66716 54.5272 4.47325 53.3333 3.00049 53.3333C1.52773 53.3333 0.333822 54.5272 0.333822 56ZM456.334 56C456.334 57.4728 457.528 58.6667 459.001 58.6667C460.473 58.6667 461.667 57.4728 461.667 56C461.667 54.5272 460.473 53.3333 459.001 53.3333C457.528 53.3333 456.334 54.5272 456.334 56ZM3.00049 56.5L459.001 56.5V55.5L3.00049 55.5V56.5Z" fill="black"/>
                <path d="M0.333333 532.5C0.333333 533.973 1.52724 535.167 3 535.167C4.47276 535.167 5.66667 533.973 5.66667 532.5C5.66667 531.027 4.47276 529.833 3 529.833C1.52724 529.833 0.333333 531.027 0.333333 532.5ZM577.358 532.5C577.358 533.973 578.552 535.167 580.024 535.167C581.497 535.167 582.691 533.973 582.691 532.5C582.691 531.027 581.497 529.833 580.024 529.833C578.552 529.833 577.358 531.027 577.358 532.5ZM3 533H580.024V532H3V533Z" fill="black"/>
                <path d="M802.326 147.229C802.326 148.701 803.52 149.895 804.993 149.895C806.466 149.895 807.66 148.701 807.66 147.229C807.66 145.756 806.466 144.562 804.993 144.562C803.52 144.562 802.326 145.756 802.326 147.229ZM1220.33 147.229C1220.33 148.701 1221.53 149.895 1223 149.895C1224.47 149.895 1225.67 148.701 1225.67 147.229C1225.67 145.756 1224.47 144.562 1223 144.562C1221.53 144.562 1220.33 145.756 1220.33 147.229ZM804.993 147.729H1223V146.729H804.993V147.729Z" fill="black"/>
                <path d="M765.333 428.242C765.333 429.715 766.527 430.909 768 430.909C769.472 430.909 770.666 429.715 770.666 428.242C770.666 426.769 769.472 425.576 768 425.576C766.527 425.576 765.333 426.769 765.333 428.242ZM1220.33 428.242C1220.33 429.715 1221.53 430.909 1223 430.909C1224.47 430.909 1225.67 429.715 1225.67 428.242C1225.67 426.769 1224.47 425.576 1223 425.576C1221.53 425.576 1220.33 426.769 1220.33 428.242ZM768 428.742H1223V427.742H768V428.742Z" fill="black"/>
            </svg>
        </div>
    </div>

    const distributionHeader = <div style={{display: 'flex'}}>
        <div style={{flex: 1}}></div>
        <div style={{flex: 1, textTransform: 'uppercase', fontSize: '24px', lineHeight: '36px', border: '1px solid black', textAlign: 'right', padding: '12px'}}>Have key distribution market in</div>
    </div>

    const distribution = <div>
        <div style={{margin: '2em 1em'}}>
            {distributionHeader}
        </div>
        <div style={{margin: '1em'}}>
            All diagrams display current depersonalized startups' data
        </div>
        <div style={{width: "1000px", padding: "0 1em", boxSizing: "border-box", margin: 'auto'}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{width: 188 / 565 * 900}}>
                    <div>Europe & UK</div>
                    <div style={{height: "40px", borderLeft: "1px solid black", borderTop: "1px solid black", width: "10px"}}></div>
                    <div style={{backgroundColor: "black", height: "40px", color: "white", fontSize: "24px", textAlign: "left", lineHeight: "40px", paddingLeft: "4px", boxSizing: "border-box"}}>188</div>
                </div>
                <div style={{width: 14 / 565 * 900}}>
                    <div>US</div>
                    <div style={{height: "40px", borderLeft: "1px solid black", borderTop: "1px solid black", width: "10px"}}></div>
                    <div style={{backgroundColor: "black", height: "40px", color: "white", fontSize: "24px", textAlign: "left", lineHeight: "40px", paddingLeft: "4px", boxSizing: "border-box"}}>14</div>
                </div>
                <div style={{width: 42 / 565 * 900}}>
                    <div>Asia</div>
                    <div style={{height: "40px", borderLeft: "1px solid black", borderTop: "1px solid black", width: "10px"}}></div>
                    <div style={{backgroundColor: "black", height: "40px", color: "white", fontSize: "24px", textAlign: "left", lineHeight: "40px", paddingLeft: "4px", boxSizing: "border-box"}}>42</div>
                </div>
                <div style={{width: 200 / 565 * 900}}>
                    <div>LATAM</div>
                    <div style={{height: "40px", borderLeft: "1px solid black", borderTop: "1px solid black", width: "10px"}}></div>
                    <div style={{backgroundColor: "black", height: "40px", color: "white", fontSize: "24px", textAlign: "left", lineHeight: "40px", paddingLeft: "4px", boxSizing: "border-box"}}>200</div>
                </div>
                <div style={{width: 18 / 565 * 900}}>
                    <div>MENA</div>
                    <div style={{height: "40px", borderLeft: "1px solid black", borderTop: "1px solid black", width: "10px"}}></div>
                    <div style={{backgroundColor: "black", height: "40px", color: "white", fontSize: "24px", textAlign: "left", lineHeight: "40px", paddingLeft: "4px", boxSizing: "border-box"}}>18</div>
                </div>
                <div style={{width: 57 / 565 * 900}}>
                    <div>SEA</div>
                    <div style={{height: "40px", borderLeft: "1px solid black", borderTop: "1px solid black", width: "10px"}}></div>
                    <div style={{backgroundColor: "black", height: "40px", color: "white", fontSize: "24px", textAlign: "left", lineHeight: "40px", paddingLeft: "4px", boxSizing: "border-box"}}>57</div>
                </div>
                <div style={{width: 46 / 565 * 900}}>
                    <div>Others</div>
                    <div style={{height: "40px", borderLeft: "1px solid black", borderTop: "1px solid black", width: "10px"}}></div>
                    <div style={{backgroundColor: "black", height: "40px", color: "white", fontSize: "24px", textAlign: "left", lineHeight: "40px", paddingLeft: "4px", boxSizing: "border-box"}}>46</div>
                </div>
            </div>
        </div>
    </div>

    const footer = <div style={{backgroundColor: 'black', color: 'white', display: 'flex', padding: '2em', fontSize: '16px', lineHeight: '24px', textTransform: 'uppercase', justifyContent: 'space-evenly', boxSizing: 'border-box', flexWrap: 'wrap'}}>
        <div>
            <div style={{marginBottom: '24px'}}>Hungryteams Inc &copy; 2024</div>
            <div>About</div>
            <div>Users Agreement</div>
            <div>Private Statement</div>
        </div>
        <div>
            <div style={{marginBottom: '24px'}}>Data@hungryteams.com</div>
            <div>LinkedIn</div>
            <div>Twitter</div>
            <div>Facebook</div>
        </div>
        <div>
            <div onClick={() => navigate("/newtoinvest")}>To Invest</div>
            <div onClick={() => navigate("/newtopitch")}>To Pitch</div>
            <div onClick={() => navigate("/newpricing")}>Pricing</div>
            <div onClick={() => navigate("/newdatapage")}>Data</div>
            <div>FAQ</div>
        </div>
    </div>

    return (
        <div style={{minWidth: '1000px'}}>
            {header}
            {sectorStats}
            {stages}
            {distribution}
            <div style={{marginTop: "2em", marginBottom: "2em", marginLeft: "auto", marginRight: "1em", width: "35%", cursor: "pointer"}}>
                <div style={{flex: 5, marginLeft: '4px', backgroundColor: 'black', color: 'white', padding: '0.5em', fontSize: '20px'}}>click here for /pricing</div>
            </div>
            {footer}
        </div>
    );
}

export default Data;