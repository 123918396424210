import React from 'react';
import { useNavigate } from 'react-router-dom';

function Pricing () {

    const navigate = useNavigate();

    const corePricingPlans = {
        investors: [
            {id: 0, name: "Regular", items: ["Offer to match with the startup is only valid for 48hrs", "All archived startups (that joined HungryTeams earlier) would be filtered by vertical, grouped in 10s, and sent by email"], fee: "Free of Charge"},
            {id: 1, name: "Advanced", items: ["Timeless offer to match", "Precise matching with already joined startups", "Access to any startup in the list and manual filtering upon request"], fee: "$25 / month"}
        ],
        providers: [
            {id: 0, name: "Special", items: ["Promotion on the main page", "Personal emails", "Other collaborations and more deep integration with the HungryTeams platform"], fee: "Request an offer"}
        ],
        founders: [
            {id: 0, name: "Report", items: ["You can apply for a detailed report of matching the following items:", "Find out how many times your blurb was shown to the investors", "How many clicks you have had so far", "Get the advice on how to improve your pitch on the platform to perform better"], fee: "Buy for $10 / one time"}
        ]
    };

    const headerView = <div style={{margin: '1em'}}>
        <div style={{backgroundColor: "black", color: "white", fontSize: "80px", width: "fit-content", lineHeight: "80px"}}>
            Plans
        </div>
        <div style={{backgroundColor: "white", color: "black", fontSize: "80px", lineHeight: "80px"}}>
            & Pricing
        </div>
    </div>

    const forInvestors = <div style={{margin: "1em"}}>
        <div style={{backgroundColor: "black", color: "white", fontSize: "24px", lineHeight: "36px", padding: "0 16px", boxSizing: "border-box", textTransform: "uppercase"}}>
            for investors
        </div>
        <div style={{margin: "36px 16px", width: "50%", color: "#7D7D7D", fontSize: "18px", lineHeight: "24px"}}>
            <div>If you actively use the outbound model in building your pipeline, HungryTeams will definitely become a great source of deal flow. </div>
            <div>You can always try and check it yourself. </div>
            <div>Fill the form anytime, get only matching pitches, choose any to match with.</div>
        </div>
        <div style={{display: "flex", flexWrap: "wrap"}}>
            {corePricingPlans.investors.map((pp, index) => {
                return <div key={index} style={{margin: "2em", padding: "2em", boxSizing: "border-box", width: "45%", justifyContent: "space-evenly", borderTop: "2px solid #cecece", borderRight: "2px solid #cecece"}}>
                    <div style={{textTransform: "uppercase", fontSize: "24px"}}>{pp.name}</div>
                    <div style={{margin: "1em 0"}}>{pp.items.map((item, index) => {
                        return <div key={index} style={{margin: "1em 0"}}>
                            - {item}
                        </div>
                    })}</div>
                    <div style={{border: "1px solid black", padding: "4px", boxSizing: "border-box", fontSize: "24px", width: "60%", textAlign: "center", cursor: "pointer"}}>{pp.fee}</div>
                </div>
            })}
        </div>
    </div>

    const forProviders = <div style={{margin: "1em"}}>
        <div style={{backgroundColor: "black", color: "white", fontSize: "24px", lineHeight: "36px", padding: "0 16px", boxSizing: "border-box", textTransform: "uppercase"}}>
            for providers
        </div>
        <div style={{display: "flex", justifyContent: "space-evenly", margin: "36px 16px", fontSize: "18px", lineHeight: "24px"}}>
            <div style={{color: "#7D7D7D"}}>
                <div>Service and IT-providers, Financial and Legal Counsels, Immigration agencies and Country's authorities</div>
                <div>Reach best talents directly to offer locations, programs and other values</div>
            </div>
            <div>
                {corePricingPlans.providers.map((prov, index) => {
                    return <div key={index} style={{margin: "2em", padding: "2em", boxSizing: "border-box", justifyContent: "space-evenly", borderTop: "2px solid #cecece", borderRight: "2px solid #cecece"}}>
                        <div style={{textTransform: "uppercase", fontSize: "24px"}}>{prov.name}</div>
                        <div style={{margin: "1em 0"}}>{prov.items.map((item, index) => {
                            return <div key={index} style={{margin: "1em 0"}}>
                                - {item}
                            </div>
                        })}</div>
                        <div style={{border: "1px solid black", padding: "4px", boxSizing: "border-box", fontSize: "24px", width: "60%", textAlign: "center", cursor: "pointer"}}>{prov.fee}</div>
                    </div>
                })}
            </div>
        </div>
    </div>

    const forFounders = <div style={{margin: "1em"}}>
        <div style={{backgroundColor: "black", color: "white", fontSize: "24px", lineHeight: "36px", padding: "0 16px", boxSizing: "border-box", textTransform: "uppercase"}}>
            for providers
        </div>
        <div style={{display: "flex", justifyContent: "space-evenly", margin: "36px 16px", fontSize: "18px", lineHeight: "24px"}}>
            <div style={{color: "#7D7D7D"}}>
                <div>HungryTeams is a unique platform to find investors that are active in the area of your business</div>
                <div>Investors are receiving personalized emails in case your startup is matching with their interests</div>
                <div>With this offer you can fine-tune your pitch for relevant investors</div>
            </div>
            <div>
                {corePricingPlans.founders.map((founder, index) => {
                    return <div key={index} style={{margin: "2em", padding: "2em", boxSizing: "border-box", justifyContent: "space-evenly", borderTop: "2px solid #cecece", borderRight: "2px solid #cecece"}}>
                        <div style={{textTransform: "uppercase", fontSize: "24px"}}>{founder.name}</div>
                        <div style={{margin: "1em 0"}}>{founder.items.map((item, index) => {
                            return <div key={index} style={{margin: "1em 0"}}>
                                - {item}
                            </div>
                        })}</div>
                        <div style={{border: "1px solid black", padding: "4px", boxSizing: "border-box", fontSize: "24px", width: "60%", textAlign: "center", cursor: "pointer"}}>{founder.fee}</div>
                    </div>
                })}
            </div>
        </div>
    </div>

    const footer = <div style={{backgroundColor: 'black', color: 'white', display: 'flex', padding: '2em', fontSize: '16px', lineHeight: '24px', textTransform: 'uppercase', justifyContent: 'space-evenly', boxSizing: 'border-box', flexWrap: 'wrap'}}>
        <div>
            <div style={{marginBottom: '24px'}}>Hungryteams Inc &copy; 2024</div>
            <div>About</div>
            <div>Users Agreement</div>
            <div>Private Statement</div>
        </div>
        <div>
            <div style={{marginBottom: '24px'}}>Data@hungryteams.com</div>
            <div>LinkedIn</div>
            <div>Twitter</div>
            <div>Facebook</div>
        </div>
        <div>
            <div onClick={() => navigate("/newtoinvest")}>To Invest</div>
            <div onClick={() => navigate("/newtopitch")}>To Pitch</div>
            <div onClick={() => navigate("/newpricing")}>Pricing</div>
            <div onClick={() => navigate("/newdatapage")}>Data</div>
            <div>FAQ</div>
        </div>
    </div>

    return (
        <div>
            {headerView}
            {forInvestors}
            {forProviders}
            {forFounders}
            {footer}
        </div>
    )

};

export default Pricing;