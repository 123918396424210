import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  };

function Navigation () {

    const size = useWindowSize();
    const navigate = useNavigate();

    function navClicked (path) {
      navigate(path);
    }

    const navUi = <div style={{top: 0, width: '100%', display: 'flex', backgroundColor: 'black', color: 'white', justifyContent: 'space-around', textTransform:'uppercase', flexWrap: 'wrap'}}>
        <div style={{display: 'flex', lineHeight: '32px', fontSize: '24px', marginTop: '1em', marginBottom: '1em'}}>
            <div style={{cursor: 'pointer'}} onClick={() => navClicked("/newpricing")}>Pricing</div>
            <div style={{marginLeft: '40px', cursor: 'pointer'}} onClick={() => navClicked("/newdatapage")}>Data</div>
        </div>
        <div style={{fontSize: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{margin: 0, cursor: 'pointer'}} onClick={() => navClicked("/newlandingpage")}>Hungry Teams</div>
        </div>
        <div style={{display: 'flex', lineHeight: '32px', fontSize: '24px', marginTop: '1em', marginBottom: '1em'}}>
            <div style={{cursor: 'pointer'}} onClick={() => navClicked("/newtoinvest")}>To Invest</div>
            <div style={{marginLeft: '40px', cursor: 'pointer'}} onClick={() => navClicked("/newtopitch")}>To Pitch</div>
        </div>
    </div>

    
    return (
        <div style={{minWidth: '1000px'}}>
            {navUi}
        </div>
    );
}

export default Navigation;